import { jsx as _jsx } from "react/jsx-runtime";
// react
import { memo, useMemo } from 'react';
import styled from 'styled-components';
// components
import { Boolean } from "../../atoms/Boolean/Boolean";
import { Input } from "../../atoms/Input/Input";
import { TextArea } from "../../atoms/TextArea/TextArea";
import { Select } from "../../atoms/Select/Select";
import { Date as DatePicker } from "../../atoms/Date/Date";
import { MultiSelect } from "../../atoms/MultiSelect/MultiSelect";
import { MultiSelectChip } from "../../atoms/MultiSelectChip/MultiSelectChip";
import { MultiSelectBox } from "../../atoms/MultiSelectBox/MultiSelectBox";
import { Slider } from "../../atoms/Slider/Slider";
import { RadioGroup } from "../../atoms/RadioGroup/RadioGroup";
import { BooleanTable } from "../../atoms/BooleanTable/BooleanTable";
import { TimePicker } from "../../atoms/TimePicker/TimePicker";
import { ToggleSelect } from "../../atoms";
import { DateOfBirth } from "../../molecules/DateOfBirth/DateOfBirth";
import { ContactField } from "../../molecules";
import { AddressField } from "../../molecules";
import { CareCircleMemberSelector } from "../../molecules";
import { FieldType, } from "./fieldHelper";
import { QuestionTable } from "../QuestionTable/QuestionTable";
/**
 * EditorFields is the fields of the editor.
 */
export const EditorFields = memo(({ fields, value, onChange, isDisabled = false }) => {
    // convert fields into an array ordered by the order property
    const orderedFields = useMemo(() => {
        let orderedFields = [];
        Object.keys(fields).forEach((fieldKey, i) => {
            const fieldValue = fields[fieldKey];
            const index = (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.order) ? fieldValue.order : 99999 + i;
            orderedFields[index] = {
                fieldValue: fieldValue,
                fieldKey: fieldKey,
            };
        });
        return orderedFields;
    }, [fields]);
    return (_jsx(StyledEditorFields, { children: orderedFields.map((orderedField) => {
            var _a, _b, _c, _d, _e;
            const fieldValue = orderedField.fieldValue;
            const fieldKey = orderedField.fieldKey;
            if (!fieldValue)
                return null;
            const genericProps = {
                id: fieldKey,
                key: fieldKey,
                ariaLabel: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.label,
                placeholder: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.placeholder,
                label: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.label,
                description: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.description,
                isHideTitle: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.isHideTitle,
                isDisabled: isDisabled,
                tooltip: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.tooltip,
                isRequired: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.isRequired,
                value: (value === null || value === void 0 ? void 0 : value[fieldKey]) !== undefined ? value === null || value === void 0 ? void 0 : value[fieldKey] : '',
                validate: fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.validation,
                onChange: (newValue) => {
                    onChange(Object.assign(Object.assign({}, value), { [fieldKey]: newValue }), fieldKey);
                },
            };
            switch (fieldValue.field.type) {
                case FieldType.Text:
                case FieldType.Time:
                    return _jsx(Input, Object.assign({}, genericProps));
                case FieldType.TextArea:
                    return _jsx(TextArea, Object.assign({}, genericProps));
                case FieldType.Number:
                    return _jsx(Input, Object.assign({}, genericProps, { type: 'number' }));
                case FieldType.Email:
                    return _jsx(Input, Object.assign({}, genericProps, { type: 'email' }));
                case FieldType.Password:
                    return _jsx(Input, Object.assign({}, genericProps, { type: 'password' }));
                case FieldType.DateOfBirth:
                    return _jsx(DateOfBirth, Object.assign({}, genericProps));
                case FieldType.Select:
                case FieldType.SelectCustom:
                    return (_jsx(Select, Object.assign({}, genericProps, { options: (fieldValue === null || fieldValue === void 0 ? void 0 : fieldValue.field.options) || [] })));
                case FieldType.MultiSelect:
                case FieldType.MultiSelectCustom:
                    return (_jsx(MultiSelect, Object.assign({}, genericProps, { options: fieldValue.field.options || [] })));
                case FieldType.MultiSelectChip:
                    return (_jsx(MultiSelectChip, Object.assign({}, genericProps, { isCustom: fieldValue.field.isCustom || false, options: fieldValue.field.options || [] })));
                case FieldType.MultiSelectBox:
                    return (_jsx(MultiSelectBox, Object.assign({}, genericProps, { options: fieldValue.field.options || [], maxColumns: fieldValue.field.maxColumns || 1, isCustom: fieldValue.field.isCustom || false, checkboxSize: fieldValue.field.size || 'sm' })));
                case FieldType.RadioGroup:
                    return (_jsx(RadioGroup, Object.assign({}, genericProps, { options: fieldValue.field.options || [] })));
                case FieldType.BooleanTable:
                    return (_jsx(BooleanTable, Object.assign({}, genericProps, { title: (_a = fieldValue.field.table) === null || _a === void 0 ? void 0 : _a.title, columns: ((_b = fieldValue.field.table) === null || _b === void 0 ? void 0 : _b.columns) || [], rows: ((_c = fieldValue.field.table) === null || _c === void 0 ? void 0 : _c.rows) || [] })));
                case FieldType.QuestionTable:
                    return (_jsx(QuestionTable, Object.assign({}, genericProps, { value: value, onChange: genericProps.onChange, fieldValue: orderedField.fieldValue, fieldKey: fieldKey })));
                case FieldType.Boolean:
                    return _jsx(Boolean, Object.assign({}, genericProps));
                case FieldType.DatePicker:
                case FieldType.DateRange:
                    return _jsx(DatePicker, Object.assign({}, genericProps));
                case FieldType.Contact:
                    return _jsx(ContactField, Object.assign({}, genericProps));
                case FieldType.Address:
                    return _jsx(AddressField, Object.assign({}, genericProps));
                case FieldType.Slider:
                    return _jsx(Slider, Object.assign({}, genericProps, { min: 1, max: 10 }));
                case FieldType.SliderSelect:
                    return (_jsx(Slider, Object.assign({}, genericProps, { isSegmented: true, min: 0, max: (((_d = fieldValue.field.options) === null || _d === void 0 ? void 0 : _d.length) || 1) - 1, segmentLabels: (_e = fieldValue.field.options) === null || _e === void 0 ? void 0 : _e.map((opt) => opt.label) })));
                case FieldType.SliderSeverity:
                    return (_jsx(Slider, Object.assign({}, genericProps, { min: 1, max: 5, isSegmented: true, segmentLabels: [
                            'None',
                            'Mild',
                            'Moderate',
                            'Significant',
                            'Critical',
                        ] })));
                case FieldType.CareCircleMemberSelector:
                    return (_jsx(CareCircleMemberSelector, Object.assign({}, genericProps, { careCircle: value, relationshipOptions: fieldValue.field.options || [], onInvite: fieldValue.onInvite })));
                case FieldType.TimePicker:
                    return (_jsx(TimePicker, Object.assign({}, genericProps, { options: fieldValue.field.options || [] })));
                case FieldType.ToggleSelect:
                    return (_jsx(ToggleSelect, Object.assign({}, genericProps, { options: fieldValue.field.options || [] })));
                default:
                    return null;
            }
        }) }));
});
const StyledEditorFields = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing[16]};
  width: 100%;
`;
EditorFields.displayName = 'EditorFields';
export default EditorFields;
