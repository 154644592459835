// react
import { useCallback, useEffect, useMemo } from 'react';

// mixpanel
import { MixpanelEvent, useMixpanel } from 'hooks';

// components
import { BookingDetails, BookingDetailsState } from '@karehero/llama';

// hooks
import { useCareProfile } from 'hooks';

// store
import { dispatch } from 'store';
import { useInviteCareCircleMutation } from 'store/api/careCircle';
import { useGetAllRelationshipQuery } from 'store/api/relationship';

// models
import { CareCircleMemberInvite } from '@karehero/models';
import { newToast } from 'store/slices/toastSlice';

/**
 * BookCallDetails shows the BookingDetails component.
 */
const BookCallDetails = () => {
  // hooks
  const { track } = useMixpanel();
  const { careCircle } = useCareProfile();
  const [inviteCareCircle] = useInviteCareCircleMutation();
  const { data: relationships } = useGetAllRelationshipQuery();

  // memos
  const relationshipOptions = useMemo(
    () =>
      relationships?.map((relationship) => ({
        value: relationship.id,
        label: relationship.name,
      })),
    [relationships],
  );

  // effects
  useEffect(() => {
    track(MixpanelEvent.BookCallViewed, {});
  }, [track]);

  // methods
  const handleOnSubmit = useCallback((data: BookingDetailsState) => {
    // TODO: remove this console.log when implementing the actual booking
    console.log('Selected DATA:', data);
  }, []);

  const handleInvite = useCallback(
    async (careCircleMemberInvite: CareCircleMemberInvite) => {
      try {
        const res = await inviteCareCircle(careCircleMemberInvite).unwrap();
        dispatch(newToast({ title: 'Email invite sent', variant: 'success' }));
        return res;
      } catch (e) {
        console.error(e);
      }
    },
    [inviteCareCircle],
  );

  return careCircle ? (
    <BookingDetails
      careCircle={careCircle}
      onSubmit={handleOnSubmit}
      onInvite={handleInvite}
      relationshipOptions={relationshipOptions || []}
    />
  ) : null;
};

export default BookCallDetails;
