import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// react
import { useCallback, useState } from 'react';
import styled from 'styled-components';
// components
import { Button, Container, Icon, Input, Label, RadioGroup, TextArea, ToggleSelect, } from "../../atoms";
import { CareCircleMemberSelector } from "../../molecules";
export const BookingDetails = ({ relationshipOptions, careCircle, onSubmit, onInvite, }) => {
    // state
    const [eventDetails, setEventDetails] = useState({
        callType: 'phone-call',
    });
    // methods
    const handleSubmit = useCallback(() => {
        if (!onSubmit)
            return;
        onSubmit({
            callType: eventDetails.callType || '',
            phoneNumber: eventDetails.phoneNumber || '',
            selectedCareCircleMemberAccountIds: eventDetails.selectedCareCircleMemberAccountIds || '',
            reasonForCall: eventDetails.reasonForCall || '',
            note: eventDetails.note || '',
        });
    }, [eventDetails, onSubmit]);
    return (_jsxs(StyledContainer, Object.assign({ size: 'md' }, { children: [_jsx(BackButton, { children: _jsx(Button, Object.assign({ size: 'sm', ariaLabel: 'Back', variant: 'secondary', iconLeft: 'chevron-left', onPress: () => window.history.back() }, { children: "Back" })) }), _jsxs(SubContainer, { children: [_jsxs(SubHeadingContainer, { children: [_jsx(Label, { text: 'Call type', id: 'call-type' }), _jsx(Icon, { icon: 'info-circle', size: 'sm' })] }), _jsx(ToggleSelect, { id: 'call-type', label: 'Select call type', isRequired: true, options: [
                            { label: 'Phone call', value: 'phone-call', icon: 'phone' },
                            { label: 'Google meet', value: 'google-meet', icon: 'video' },
                        ], onChange: (value) => {
                            setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { callType: value })));
                        }, value: eventDetails.callType })] }), eventDetails.callType === 'phone-call' && (_jsx(SubContainer, { children: _jsx(Input, { label: 'Phone number', id: 'phone-number', placeholder: 'Phone number', isRequired: true, size: 'md', type: 'number', onChange: (value) => {
                        setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { phoneNumber: value })));
                    }, value: eventDetails.phoneNumber }) })), careCircle && (_jsx(SubContainer, { children: _jsx(CareCircleMemberSelector, { id: 'care-circle-member-selector', label: 'Care circle members', description: 'Select the care circle members you want to invite to this call', maxColumns: 1, relationshipOptions: relationshipOptions, careCircle: careCircle, onInvite: onInvite, value: eventDetails.selectedCareCircleMemberAccountIds, onChange: (value) => {
                        setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { selectedCareCircleMemberAccountIds: value })));
                    } }) })), _jsxs(SubContainer, { children: [_jsx(SubHeadingContainer, { children: _jsx(Label, { text: 'Reason for call', id: 'reason-for-call' }) }), _jsx(RadioGroup, { options: [
                            { label: 'Help to find care', value: 'help-to-find-care' },
                            {
                                label: 'Understanding my loved one’s care needs',
                                value: 'understanding-my-loved-ones-care-needs',
                            },
                            {
                                label: 'Information around hospital discharge',
                                value: 'information-around-hospital-discharge',
                            },
                            {
                                label: 'Help with respite care',
                                value: 'help-with-respite-care',
                            },
                            {
                                label: 'Help with power of attorney',
                                value: 'help-with-power-of-attorney',
                            },
                            { label: 'I don’t know', value: 'i-dont-know' },
                        ], variant: 'default', maxColumns: 1, onChange: (value) => {
                            setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { reasonForCall: value })));
                        }, value: eventDetails.reasonForCall })] }), _jsx(SubContainer, { children: _jsx(TextArea, { label: 'Please share anything else that will help prepare for our meeting', id: 'note', placeholder: 'Write a note', onChange: (value) => {
                        setEventDetails((prev) => (Object.assign(Object.assign({}, prev), { note: value })));
                    }, value: eventDetails.note }) }), _jsx(Button, Object.assign({ ariaLabel: 'Book a call', variant: 'primary', onPress: handleSubmit, isFullWidth: true }, { children: "Book a call" }))] })));
};
const StyledContainer = styled(Container) `
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  margin: ${(props) => props.theme.spacing[4]};
  overflow-y: auto;
`;
const BackButton = styled.div `
  display: block;
  margin-bottom: ${(props) => props.theme.spacing[20]};
`;
const SubContainer = styled.div `
  margin-bottom: ${(props) => props.theme.spacing[24]};
`;
const SubHeadingContainer = styled.div `
  display: flex;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing[8]};
`;
BookingDetails.displayName = 'BookingDetails';
export default BookingDetails;
